import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { tableOpts, globalTableStyle } from 'Configs/table'
import ContentWrapper from 'Comps/container/ContentWrapper'
import LoadingContent from 'Elements/LoadingContent';
import Alert from 'Comps/elements/Alert'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplyIcon from '@material-ui/icons/Reply';
import Table from 'Elements/Table'
import { deleteSales, resetStatusSales, filterSalesIndex } from 'Reduxes/pos/sales/salesActions'
import { scrollToTop, money, moneyToInt, globalMessage, globalDialog } from '@/Helper'
import Label from 'Elements/Label'
import TableNoData from 'Comps/elements/TableNoData'
import Datepicker from 'Elements/Datepicker';
import PrintProvider, { Print } from "react-easy-print";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SelectFilter from 'Elements/SelectFilter'
import Button from 'Elements/Button';
import {
    FormGroup,
    FormLabel,
    TextField,
} from '@material-ui/core';
import SimpleModal from 'Elements/modal/Modal';

import { makeStyles } from '@material-ui/core/styles';
import Permission from '../../../comps/permission/Permission';
import KopSurat from 'Elements/KopSurat'
import { getAuthToken, getCurrentUser } from '@/Helper'
import printSettlement from '../../../lib/print-settlement';
import { requestSettlement } from './requests';


const useStyles = makeStyles((theme) => ({
    spacer: {
        height: "25px"
    },
    formfilter: {
        display: 'grid',
        gridTemplateColumns: '300px 300px',
        columnGap: '24px',
        marginBottom: '15px'
    },
    formextrafilter: {
        marginTop: '50px',
    },
    toggler: {
        color: '#333',
        fontSize: '13px',
        background: 'white',
        border: 0,
        cursor: 'pinter',
        fontWeight: 600,
        '& svg': {
            marginBottom: '-7px',
            marginLeft: '5px',
        },
        '&:hover': {
            color: theme.pw.teal
        },
        '&.teal': {
            color: theme.pw.teal
        }
    },
    formextrafiltercontainer: {
        border: '1px solid #ddd',
        padding: '20px',
        borderRadius: '3px',
        marginTop: '15px',
        marginBottom: '20px',
        width: '50%'
    },
    daterangeCont: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '10px',
    }
}));

export default function TableData(props) {
    const localClass = useStyles();
    const { showDetail, showForm } = props;
    const { error, success, data, loading } = useSelector(state => state.salesReducer);
    // const [data, setData] = useState([])
    // const [loading, setLoading] = useState([true])
    const classes = globalTableStyle();
    const dispatch = useDispatch();
    const [showExtraFilter, setShowExtraFilter] = useState(false);
    // const [index, setIndex] = useState([])
    const [dateFrom, setDateFrom] = useState(null)
    const [dateTo, setDateTo] = useState(null)
    let subTotal = 0;
    let totalDiscount = 0;
    let total = 0;
    let salesDiscount = 0;
    let returnDiscount = 0;

    if (data?.length > 0) {
        data.forEach(d => {
            total += d.total;
            subTotal += d.subtotal;
            // d.category === 'return' ? d.discount * -1 : d.discount
            if (d.category === 'sales' || d.category === 'cancel') salesDiscount += d.discount;
            if (d.category === 'return') returnDiscount += d.discount;
            totalDiscount = salesDiscount - returnDiscount
        });

        total = subTotal - totalDiscount
    }

    const toggleExtraFilter = event => {
        event.preventDefault()
        setShowExtraFilter(state => !state);
    }
    const handleDelete = async id => {
        let allowDelete = true;
        id.forEach(i => {
            const isFound = data.find(d => d.id === i)
            if (isFound && isFound.status !== 'draft') {
                allowDelete = false;
            }
        });

        if (!allowDelete) {
            globalMessage('You only allowed to delete data with status \'Draft\'', 'warning')
            return;
        }

        globalDialog(async () => {
            await dispatch(deleteSales(id))
            scrollToTop()
        })
    }

    const [date] = useState({ dateFilterChecked: false })

    const [subTotalTbl] = useState({ subTotalTblFilterChecked: false })

    const [disc] = useState({ discFilterChecked: false })

    const [totalTbl] = useState({ totalTblFilterChecked: false })

    const handleChange = (val) => {
        val.props.name === 'start_date' && setDateFrom(val.value)
        val.props.name === 'end_date' && setDateTo(val.value)
    }
    const applyFilter = async () => {
        let req;
        if (dateFrom === null && dateTo === null) {
            req = await dispatch(filterSalesIndex({ date_from: 'beginning' }))
        } else if (dateFrom === null && dateTo !== null) {
            req = await dispatch(filterSalesIndex({ date_from: new Date().toISOString().split('T')[0], date_to: dateTo }))
        } else if (dateFrom !== null && dateTo === null) {
            req = await dispatch(filterSalesIndex({ date_from: dateFrom, date_to: new Date().toISOString().split('T')[0], }))
        } else {
            req = await dispatch(filterSalesIndex({ date_from: dateFrom, date_to: dateTo }))
        }
    }

    let promotion = [];
    let uniquePromotion = [];
    let promotionOptions = [];

    if (data?.length > 0) {
        promotion = data.map(item => {
            return [...new Set(item.pos_item.map(pos => pos.promotion.promotion_name))]
        }).flat(1).filter(Boolean)
        uniquePromotion = [...new Set(promotion)]
        promotionOptions = uniquePromotion.map(item => {
            return {
                value: item,
                label: item
            }
        })
    }

    const table = tableOpts({
        handleDelete,
        data,
        colIdPosition: 0,
        filename: "Sales_Data",
        table: {
            columns: [
                {
                    name: 'id',
                    options: {
                        display: false,
                        filter: false,
                        print: false,
                        viewColumns: false
                    }
                },
                {
                    name: 'no',
                    label: 'No.',
                    options: {
                        filter: false,
                        print: true,
                        customBodyRender: (value, tableMeta) => {
                            return tableMeta.rowIndex + 1
                        }
                    }
                },


                {
                    name: 'transaction_date',
                    label: 'Date',
                    options: {
                        filter: false,
                        filterType: 'custom',
                        customFilterListOptions: {
                            render: v => {
                                if (v[0] && v[1] && date.dateFilterChecked) {
                                    return [`Min Date: ${v[0]}`, `Max Date: ${v[1]}`];
                                } else if (v[0] && v[1] && !date.dateFilterChecked) {
                                    return `Min Date: ${v[0]}, Max Date: ${v[1]}`;
                                } else if (v[0]) {
                                    return `Min Date: ${v[0]}`;
                                } else if (v[1]) {
                                    return `Max Date: ${v[1]}`;
                                }
                                return [];
                            },
                            update: (filterList, filterPos, index) => {

                                if (filterPos === 0) {
                                    filterList[index].splice(filterPos, 1, '');
                                } else if (filterPos === 1) {
                                    filterList[index].splice(filterPos, 1);
                                } else if (filterPos === -1) {
                                    filterList[index] = [];
                                }

                                return filterList;
                            }
                        },

                        filterOptions: {
                            names: [],
                            logic(date, filters) {

                                if (filters[0] && filters[1]) {
                                    return Date.parse(date) < Date.parse(filters[0]) || Date.parse(date) > Date.parse(filters[1]);
                                } else if (Date.parse(filters[0])) {
                                    return Date.parse(date) < Date.parse(filters[0]);
                                } else if (Date.parse(filters[1])) {
                                    return Date.parse(date) > Date.parse(filters[1]);
                                }
                                return false;
                            },
                            display: (filterList, onChange, index, column) => (
                                <div>
                                    <FormLabel className={localClass.title}>Date</FormLabel>
                                    <div className={localClass.spacer}></div>
                                    <FormGroup row>
                                        <TextField
                                            variant="outlined"
                                            label='min'
                                            value={filterList[index][0] || ''}
                                            onChange={event => {
                                                filterList[index][0] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                            style={{
                                                width: '45%',
                                                marginRight: '5%'
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            type="date" />
                                        <TextField
                                            variant="outlined"
                                            label='max'
                                            value={filterList[index][1] || ''}
                                            onChange={event => {
                                                filterList[index][1] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                            style={{
                                                width: '45%'
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            type="date" />

                                    </FormGroup>
                                </div>
                            )
                        }
                    }
                }, {
                    name: 'code',
                    label: 'ID',
                    options: {
                        filter: false
                    }

                }, {
                    name: 'branch_name',
                    label: 'Branch',
                    options: {
                        filter: false,
                        filterType: 'checkbox',


                    }
                    // }, {
                    //     name: "created_by_contact_name",
                    //     label: 'Contact',
                    //     options: {
                    //         filter: false
                    //     }
                }, {
                    name: "type",
                    label: 'Type',
                    options: {
                        filter: true,
                        filterType: 'checkbox'
                    }

                }, {
                    name: "category",
                    label: 'Category',
                    options: {
                        filter: true,
                        filterType: 'checkbox',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const FLAG_RETURN = 'Reference to transaction';
                            const currentIndex = tableMeta.rowIndex;

                            if (data?.length > 0) {
                                const currentData = data[currentIndex];
                                if (currentData?.note?.includes(FLAG_RETURN)) {
                                    return 'return'
                                }

                                if (currentData.category === 'return') {
                                    return 'refund'
                                }

                                return value
                            }
                        }
                    },
                }, {
                    name: "status",
                    label: 'Status',
                    options: {

                        customBodyRender: (value, tableMeta, updateValue) => {
                            return <Label
                                text={value}
                                type={value === 'draft'
                                    ? 'grey'
                                    : 'success'} />
                        },
                        filter: true,
                        filterType: 'checkbox'
                    }
                }, {
                    name: "subtotal",
                    label: 'Sub Total',
                    options: {
                        customBodyRender: (value) => {
                            return `IDR ${money(value)} `
                        },
                        filter: true,
                        filterType: 'custom',
                        customFilterListOptions: {
                            render: v => {
                                if (v[0] && v[1] && subTotalTbl.subTotalTblFilterChecked) {
                                    return [`Min Price: ${v[0]}`, `Max Price: ${v[1]}`];
                                } else if (v[0] && v[1] && !subTotalTbl.subTotalTblFilterChecked) {
                                    return `Min Price: ${v[0]}, Max Price: ${v[1]}`;
                                } else if (v[0]) {
                                    return `Min Price: ${v[0]}`;
                                } else if (v[1]) {
                                    return `Max Price: ${v[1]}`;
                                }
                                return [];
                            },
                            update: (filterList, filterPos, index) => {

                                if (filterPos === 0) {
                                    filterList[index].splice(filterPos, 1, '');
                                } else if (filterPos === 1) {
                                    filterList[index].splice(filterPos, 1);
                                } else if (filterPos === -1) {
                                    filterList[index] = [];
                                }

                                return filterList;
                            }
                        },
                        filterOptions: {
                            names: [],
                            logic(price, filters) {

                                if (filters[0] && filters[1]) {
                                    return moneyToInt(price) < parseInt(filters[0]) || moneyToInt(price) > parseInt(filters[1]);
                                } else if (filters[0]) {
                                    return moneyToInt(price) < filters[0];
                                } else if (filters[1]) {
                                    return moneyToInt(price) > filters[1];
                                }

                                return false;
                            },
                            display: (filterList, onChange, index, column) => (
                                <div>
                                    <FormLabel>Sub Total</FormLabel>
                                    <div className={localClass.spacer}></div>
                                    <FormGroup row>
                                        <TextField
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            label='min'
                                            value={filterList[index][0] || ''}
                                            onChange={event => {
                                                filterList[index][0] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                            style={{
                                                width: '45%',
                                                marginRight: '5%'
                                            }}
                                            type='number' />
                                        <TextField
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            label='max'
                                            value={filterList[index][1] || ''}
                                            onChange={event => {
                                                filterList[index][1] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                            style={{
                                                width: '45%'
                                            }}
                                            type='number' />
                                    </FormGroup>
                                </div>
                            )

                        }

                    }
                }, {
                    name: "discount",
                    label: 'Discount',
                    options: {
                        customBodyRender: (value, tableMeta) => {
                            const isReturn = tableMeta.rowData[6] === 'return'
                            return `IDR ${isReturn ? money(value) : money(value * -1)} `
                        },
                        filter: true,
                        filterType: 'custom',
                        customFilterListOptions: {
                            render: v => {
                                if (v[0] && v[1] && disc.discFilterChecked) {
                                    return [`Min Discount: ${v[0]}`, `Max Discount: ${v[1]}`];
                                } else if (v[0] && v[1] && !disc.discFilterChecked) {
                                    return `Min Discount: ${v[0]}, Max Discount: ${v[1]}`;
                                } else if (v[0]) {
                                    return `Min Discount: ${v[0]}`;
                                } else if (v[1]) {
                                    return `Max Discount: ${v[1]}`;
                                }
                                return [];
                            },
                            update: (filterList, filterPos, index) => {

                                if (filterPos === 0) {
                                    filterList[index].splice(filterPos, 1, '');
                                } else if (filterPos === 1) {
                                    filterList[index].splice(filterPos, 1);
                                } else if (filterPos === -1) {
                                    filterList[index] = [];
                                }

                                return filterList;
                            }
                        },
                        filterOptions: {
                            names: [],
                            logic(price, filters) {

                                if (filters[0] && filters[1]) {
                                    return moneyToInt(price) < parseInt(filters[0]) || moneyToInt(price) > parseInt(filters[1]);
                                } else if (filters[0]) {
                                    return moneyToInt(price) < filters[0];
                                } else if (filters[1]) {
                                    return moneyToInt(price) > filters[1];
                                }

                                return false;
                            },
                            display: (filterList, onChange, index, column) => (
                                <div>
                                    <FormLabel>Discount</FormLabel>
                                    <div className={localClass.spacer}></div>
                                    <FormGroup row>
                                        <TextField
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            label='min'
                                            value={filterList[index][0] || ''}
                                            onChange={event => {
                                                filterList[index][0] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                            style={{
                                                width: '45%',
                                                marginRight: '5%'
                                            }}
                                            type='number' />
                                        <TextField
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            label='max'
                                            value={filterList[index][1] || ''}
                                            onChange={event => {
                                                filterList[index][1] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                            style={{
                                                width: '45%'
                                            }}
                                            type='number' />
                                    </FormGroup>
                                </div>
                            )

                        }
                    }
                }, {
                    name: "total",
                    label: 'Total',
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => {
                            // const total = tableMeta.rowData[3] - tableMeta.rowData[4] const total =
                            // 100000;
                            return `IDR ${money(value)} `
                        },
                        filter: true,
                        filterType: 'custom',
                        customFilterListOptions: {
                            render: v => {
                                if (v[0] && v[1] && totalTbl.totalTblFilterChecked) {
                                    return [`Min Price: ${v[0]}`, `Max Price: ${v[1]}`];
                                } else if (v[0] && v[1] && !totalTbl.totalTblFilterChecked) {
                                    return `Min Price: ${v[0]}, Max Price: ${v[1]}`;
                                } else if (v[0]) {
                                    return `Min Price: ${v[0]}`;
                                } else if (v[1]) {
                                    return `Max Price: ${v[1]}`;
                                }
                                return [];
                            },
                            update: (filterList, filterPos, index) => {

                                if (filterPos === 0) {
                                    filterList[index].splice(filterPos, 1, '');
                                } else if (filterPos === 1) {
                                    filterList[index].splice(filterPos, 1);
                                } else if (filterPos === -1) {
                                    filterList[index] = [];
                                }

                                return filterList;
                            }
                        },
                        filterOptions: {
                            names: [],
                            logic(price, filters) {

                                if (filters[0] && filters[1]) {
                                    return moneyToInt(price) < parseInt(filters[0]) || moneyToInt(price) > parseInt(filters[1]);
                                } else if (filters[0]) {
                                    return moneyToInt(price) < filters[0];
                                } else if (filters[1]) {
                                    return moneyToInt(price) > filters[1];
                                }

                                return false;
                            },
                            display: (filterList, onChange, index, column) => (
                                <div>
                                    <FormLabel>Total</FormLabel>
                                    <div className={localClass.spacer}></div>
                                    <FormGroup row>
                                        <TextField
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            label='min'
                                            value={filterList[index][0] || ''}
                                            onChange={event => {
                                                filterList[index][0] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                            style={{
                                                width: '45%',
                                                marginRight: '5%'
                                            }}
                                            type='number' />
                                        <TextField
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            label='max'
                                            value={filterList[index][1] || ''}
                                            onChange={event => {
                                                filterList[index][1] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                            style={{
                                                width: '45%'
                                            }}
                                            type='number' />
                                    </FormGroup>
                                </div>
                            )

                        }
                    }
                    // FIELD NOMOR
                }, {
                    name: "total_qty",
                    label: 'Total Qty',
                    options: {
                        customBodyRender: (value) => {
                            return value
                            // const promotion = value.find(val => val?.promotion?.promotion_name)
                            // const promotion = value.map(val => val.promotion).flat(1)
                            // console.log(promotion)
                            // const promotionName = promotion[0]?.promotion_name ?? '-'
                            // return promotion?.promotion?.promotion_name || '-'
                            // const arrPromo = value
                            //     .map(v => v.promotion_name)
                            //     .filter(Boolean);
                            // return arrPromo.length > 0
                            //     ? [...new Set(arrPromo)].join(', ')
                            //     : '-';
                        },
                        filter: false,
                        filterType: 'custom',
                        customFilterListOptions: {
                            render: v => {
                                if (v[11].length === 1 && v[11][0]?.value === "") {  // index harus di sesuaikan dengan posisi column
                                    return [];
                                } else if (v[11].length) {
                                    const val = v[11].map(x => x.value);
                                    return [`Promotion : ${val.join(', ')}`]
                                }
                                return [];

                            },
                            update: (filterList, filterPos, index) => { // untuk update chip filter diatas table
                                if (filterPos === 0) {
                                    filterList[index] = [];
                                }
                                return filterList;

                            }
                        },
                        filterOptions: {
                            logic(value, filters) {
                                const dataFilter = filters[11]?.map(item => item.value)
                                return !dataFilter || !dataFilter.length ? false : !dataFilter.includes(value); // jika tidak ada filter akan ditampilkan semua data di table

                            },

                            display: (filterList, onChange, index, column) => (
                                <>
                                    <SelectFilter
                                        label="promotion"
                                        name="promotion"
                                        handleChange={select => {
                                            filterList[index] = select || [];
                                            onChange(filterList, index, column);
                                        }}
                                        options={promotionOptions}
                                        defaultValue={filterList[index][index]}
                                    />
                                </>
                            )
                        }
                    }
                }, {
                    name: "created_by_contact_name",
                    label: "Created By",
                }, {
                    name: "id",
                    label: 'Actions',
                    options: {
                        setCellHeaderProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: 0,
                                zIndex: 51
                            }
                        }),
                        setCellProps: () => ({

                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: "0",
                                background: "white",
                                zIndex: 51
                            }
                        }),
                        filter: false,
                        print: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const attrShow = {
                                className: 'row-action',
                                onClick: () => showDetail(value)
                            }
                            const attrReturn = {
                                className: 'row-action',
                                onClick: () => {
                                    showForm('SalesReturnEdit', value)
                                    scrollToTop()
                                }
                            }
                            const attrEdit = {
                                className: 'row-action',
                                onClick: () => {
                                    showForm(tableMeta.rowData[6] === 'sales' ? 'SalesEdit' : 'ReturnEdit', value)
                                    scrollToTop()
                                }
                            }
                            const attrDelete = {
                                className: 'row-action',
                                onClick: () => handleDelete([value])
                            }

                            const paymentStatus = tableMeta.rowData[7]
                            const paymentCategory = tableMeta.rowData[6]

                            return (
                                <>
                                    {
                                        (paymentStatus === 'paid' && paymentCategory !== 'return' && paymentCategory !== 'cancel') &&
                                        <Permission
                                            component={
                                                <ReplyIcon {...attrReturn} />
                                            }
                                            type="update"
                                            subMenu="sales_pos"
                                        ></Permission>
                                    }
                                    < VisibilityIcon {...attrShow} />
                                    {
                                        (paymentCategory !== 'cancel') && 
                                        <Permission
                                            component={
                                                <EditIcon {...attrEdit} />
                                            }
                                            type="update"
                                            subMenu="sales_pos"
                                        ></Permission>
                                    }
                                    {
                                        paymentStatus === 'draft' &&
                                        <Permission
                                            component={
                                                <DeleteIcon {...attrDelete} />
                                            }
                                            type="delete"
                                            subMenu="sales_pos"
                                        ></Permission>
                                    }
                                </>
                            );
                        }
                    }
                }
            ]
        }
    })

    //settlement----------------------------------------------------------------
    const user = getCurrentUser()
    const [modalOpen, setModalOpen] = useState(false);
    const [loadingSettlement, setLoadingSettlement] = useState(false)

    const [printErr, setPrintErr] = useState(false)
    const [printSuc, setPrintSuc] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    function handleCloseModal() {
        setModalOpen(false);
        setLoadingSettlement(false);
        setPrintErr(false);
        setPrintSuc(false);
        setErrorMessage(null)
    }

    async function submitPrintSettlement(data) {
        try {
            const options = {
                printerName: "PW_POS_PRINTER",
            };
            if (data?.length > 0) {
                for (const item of data) {
                    printSettlement(item, options)
                }
            }
            setPrintSuc(true);
            await new Promise(resolve => setTimeout(resolve, 1000));
            handleCloseModal()
        } catch (error) {
            console.log(error)
            setPrintErr(true)
        }
    }

    async function handleSubmitSettlement() {
        try {
            setLoadingSettlement(true);
            const response = await requestSettlement();
            const data = response?.data;
            if (data) {
                await submitPrintSettlement(data)
            } else {
                setPrintErr(true)
                setErrorMessage(response?.message)
                await new Promise(resolve => setTimeout(resolve, 2000));
                handleCloseModal()
            }
            // await new Promise(resolve => setTimeout(resolve, 2000));
        } catch (error) {
            console.log(error)
        }
    }

    function onClickSettlement() {
        setModalOpen(true);
    }

    return (
        <PrintProvider>
            <Print single name="print-sales-table-1" >
                <KopSurat />
                <div className="spacer-60"></div>
                <ContentWrapper
                    title="Sales"
                    subtitle="showing table of sales"
                    buttons={[
                        {
                            label: 'Settlement',
                            style: 'TileMd',
                            icon: 'print',
                            onClick: () => onClickSettlement(),
                            type: 'create',
                            subMenu: 'sales_pos',
                        },
                        {
                            label: 'Add Sales',
                            style: 'OrangeMd',
                            icon: 'plus-circle',
                            onClick: () => showForm('SalesNew'),
                            type: 'create',
                            subMenu: 'sales_pos',
                        }, {
                            label: 'Add Sales Refund',
                            style: 'DarkblueMd',
                            icon: 'plus-circle',
                            marginLeft: "ml-10",
                            onClick: () => showForm('ReturnNew'),
                            type: 'create',
                            subMenu: 'sales_pos',
                        }
                    ]}
                >
                    <>
                        {
                            success &&
                            <Alert hide={() => dispatch(resetStatusSales())} option={{ type: 'success', message: success }} />
                        }
                        {
                            error && <Alert hide={() => dispatch(resetStatusSales())} option={{ type: 'error', message: error }} />
                        }
                        {
                            loading ? <LoadingContent />
                                : (
                                    data.length > 0 ?
                                        <>
                                            <div className="sales-header">
                                                <div className="sales-item">
                                                    <p>Sub Total</p>
                                                    <p>IDR {money(subTotal)}</p>
                                                </div>
                                                <div className="sales-item">
                                                    <p>Total Discount</p>
                                                    <p>IDR {money(totalDiscount)}</p>
                                                </div>
                                                <div className="sales-item">
                                                    <p>Total</p>
                                                    <p>IDR {money(total)}</p>
                                                </div>
                                            </div>
                                            <div className={localClass.formextrafilter}>
                                                <a href="#" className={`${localClass.toggler} ${showExtraFilter ? 'teal' : ''}`} onClick={toggleExtraFilter}>
                                                    {showExtraFilter ? 'Hide' : 'Show'} Extra Filter
                                                    {showExtraFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </a>
                                                {
                                                    showExtraFilter &&
                                                    <div className={localClass.formextrafiltercontainer}>
                                                        <div className={localClass.formFilter}>
                                                            <div className={localClass.daterangeCont}>
                                                                <Datepicker
                                                                    handleChange={handleChange}
                                                                    label="Start date"
                                                                    name="start_date"
                                                                    value={dateFrom}
                                                                />
                                                                <Datepicker
                                                                    // {...(startDate ? { minDate: startDate } : {})}
                                                                    name="end_date"
                                                                    handleChange={handleChange}
                                                                    label="end date"
                                                                    value={dateTo}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-20">
                                                            <Button
                                                                onClick={applyFilter}
                                                                style="OrangeWideMdRect"
                                                                icon="check-circle"
                                                                label="Apply Filter"
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <Table {...table} className={classes.table} />
                                        </>
                                        :
                                        <>
                                            <div className={localClass.formextrafilter}>
                                                <a href="#" className={`${localClass.toggler} ${showExtraFilter ? 'teal' : ''}`} onClick={toggleExtraFilter}>
                                                    {showExtraFilter ? 'Hide' : 'Show'} Extra Filter
                                                    {showExtraFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </a>
                                                {
                                                    showExtraFilter &&
                                                    <div className={localClass.formextrafiltercontainer}>
                                                        <div className={localClass.formFilter}>
                                                            <div className={localClass.daterangeCont}>
                                                                <Datepicker
                                                                    handleChange={handleChange}
                                                                    label="Start date"
                                                                    name="start_date"
                                                                    value={dateFrom}
                                                                />
                                                                <Datepicker
                                                                    // {...(startDate ? { minDate: startDate } : {})}
                                                                    name="end_date"
                                                                    handleChange={handleChange}
                                                                    label="end date"
                                                                    value={dateTo}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-20">
                                                            <Button
                                                                onClick={applyFilter}
                                                                style="OrangeWideMdRect"
                                                                icon="check-circle"
                                                                label="Apply Filter"
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <TableNoData />
                                        </>
                                )
                        }
                    </>
                </ContentWrapper>
            </Print>
            <SimpleModal
                isOpen={modalOpen}
                setIsOpen={setModalOpen}
            >
                <div className="modal-stockopname">
                    <p>Do you confirm the settlement of this transaction? Please be advised that settled data is non-reversible.</p>
                    {
                        printSuc &&
                        <div className="print-notif mt-30">
                            <Alert
                                option={{ type: 'success', message: "Printed Settlement Successfully" }}
                            />
                        </div>
                    }
                    {
                        printErr &&
                        <div className="print-notif mt-30">
                            <Alert
                                option={{ type: 'error', message: errorMessage ?? "Settlement Failed to Print" }}
                            />
                        </div>
                    }
                    <div className="button-container">
                        <Button
                            onClick={() => {
                                handleCloseModal()
                            }}
                            label="Cancel"
                            disabled={loadingSettlement}
                        />
                        <Button
                            onClick={handleSubmitSettlement}
                            label={loadingSettlement ? 'Confirming...' : 'Confirm'}
                            disabled={loadingSettlement}
                        />
                    </div>
                </div>
            </SimpleModal>
        </PrintProvider>

    )
}