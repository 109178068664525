import { handleReq, handleRequest, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'

const baseUrl = process.env.PW_SETTLEMENT_URL

const type = "SETTLEMENT_HISTORY"

export const SETTLEMENT_HISTORY_SET = 'SETTLEMENT_HISTORY_SET'
export const SETTLEMENT_HISTORY_ERROR = 'SETTLEMENT_HISTORY_ERROR'
export const SETTLEMENT_HISTORY_ADD = 'SETTLEMENT_HISTORY_ADD'
export const SETTLEMENT_HISTORY_SHOW = 'SETTLEMENT_HISTORY_SHOW'
export const SETTLEMENT_HISTORY_EDIT = 'SETTLEMENT_HISTORY_EDIT'
export const SETTLEMENT_HISTORY_UPDATE = 'SETTLEMENT_HISTORY_UPDATE'
export const SETTLEMENT_HISTORY_DELETE = 'SETTLEMENT_HISTORY_DELETE'
export const SETTLEMENT_HISTORY_LOADING = 'SETTLEMENT_HISTORY_LOADING'
export const SETTLEMENT_HISTORY_RESET = 'SETTLEMENT_HISTORY_RESET'
export const SETTLEMENT_HISTORY_CONFIRM = 'SETTLEMENT_HISTORY_CONFIRM'
export const SETTLEMENT_HISTORY_STOP_LOADING = 'SETTLEMENT_HISTORY_STOP_LOADING'

export const loadSettlementHistory = (params = {}) => async (dispatch, getState) => {
    if (Object.keys(params)?.length > 0) {
        return handleRequest({
            type,
            dispatch,
            body: () => reqGet(`${baseUrl}/index-history`),
            next: (payload) => dispatch({ type: SETTLEMENT_HISTORY_SET, payload })
        })    
    }
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${baseUrl}/index-history`),
        next: (payload) => dispatch({ type: SETTLEMENT_HISTORY_SET, payload })
    })
}
export const addSettlementHistory = data => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${baseUrl}/index-history`, data),
        next: (payload) => dispatch({ type: SETTLEMENT_HISTORY_ADD, payload })
    })
}
export const updateSettlementHistory = (idEdit, data) => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${baseUrl}/index-history/${idEdit}`, data),
        next: (payload) => dispatch({ type: SETTLEMENT_HISTORY_UPDATE, payload })
    })
}
export const deleteSettlementHistory = id => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${baseUrl}/index-history/`, id),
        next: () => dispatch({ type: SETTLEMENT_HISTORY_DELETE, payload: id })
    })
}
export const showSettlementHistory = idx => {
    return {
        type: SETTLEMENT_HISTORY_SHOW,
        payload: idx
    }
}

export const getSettlementHistoryById = idx => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${baseUrl}/index-history/${idx}`),
    })
}

export const setEditSettlementHistory = idEdit => {
    return {
        type: SETTLEMENT_HISTORY_EDIT,
        payload: idEdit,
        loading: false
    }
}
export const resetStatusSettlementHistory = () => {
    return {
        type: SETTLEMENT_HISTORY_RESET
    }
}
export const updateStatus = (id, type) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqPatch(`${baseUrl}/index-history/${id}/${type}`),
        next: () => dispatch({ type: SETTLEMENT_HISTORY_CONFIRM, payload: { type, id } })
    })
}
export const settlementHistoryTemp = (id, type) => {
    return {
        type: SETTLEMENT_HISTORY_CONFIRM,
        payload: { id, type },
    }
}