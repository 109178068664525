import React, { useState } from 'react'
import TableDataSSR from './TableDataSSR';
import { getCurrentUser } from '@/Helper'
import printSettlement from '../../../lib/print-settlement';
import Alert from 'Comps/elements/Alert'
import moment from 'moment';
import { makeGetParam } from 'Configs/request.js'
import TableData from './TableData';
import { getDetailHistory } from './helpers';

const initialFilters = {
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
}

function SettlementHistory() {
    const [content, setContent] = useState('table')
    const [rowId, setRowId] = useState(null)
    const [filters, setFilters] = useState({
        ...initialFilters
    })
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [params, setParams] = useState(makeGetParam({ date_from: initialFilters.date_from, date_to: initialFilters.date_to }))

    const [printErr, setPrintErr] = useState(false)
    const [printSuc, setPrintSuc] = useState(false)

    const user = getCurrentUser()

    async function submitPrintSettlement(item) {
        try {
            const id = item?.settle_id
            const data = await getDetailHistory(id)
            if (data) {
                const options = {
                    printerName: "PW_POS_PRINTER",
                };
                if (data?.length > 0) {
                    for (const item of data) {
                        printSettlement({...(item ?? {}), type: 'copy', history: item}, options)
                    }
                }
                setPrintSuc(true);
                await new Promise(resolve => setTimeout(resolve, 1000));
                setPrintSuc(false);
            } else {
                setPrintErr(true);
                await new Promise(resolve => setTimeout(resolve, 1000));
                setPrintErr(false);
            }
        } catch (error) {
            console.log(error)
            setPrintErr(true)
        }
    }

    async function downloadPdf(item) {
        try {
            const id = item?.settle_id
            const data = await getDetailHistory(id)
            if (data) {
                const options = {
                    printerName: "PW_POS_PRINTER",
                    isPdf: true,
                };
                if (data?.length > 0) {
                    for (const item of data) {
                        printSettlement({...(item ?? {}), type: 'copy', history: item}, options)
                    }
                }
            } else {
                setPrintErr(true);
                await new Promise(resolve => setTimeout(resolve, 1000));
                setPrintErr(false);
            }
        } catch (error) {
            console.log(error)
            setPrintErr(true)
            await new Promise(resolve => setTimeout(resolve, 1000));
            setPrintSuc(false);
        }
    }

    const renderContent = (contentType) => {
        switch (contentType) {
            case 'table':
                // return (
                //     <TableData
                //         printSettlement={submitPrintSettlement}
                //         downloadPdf={downloadPdf}
                //         indexSetPage={setPage}
                //         indexSetRowsPerPage={setRowsPerPage}
                //         indexSetFilters={setFilters}
                //         indexSetParams={setParams}
                //         initialPage={page}
                //         initialRowsPerPage={rowsPerPage}
                //         initialFilters={filters}
                //         initialParams={params}
                //     />
                // )
                return (
                    <TableDataSSR
                        printSettlement={submitPrintSettlement}
                        downloadPdf={downloadPdf}
                        indexSetPage={setPage}
                        indexSetRowsPerPage={setRowsPerPage}
                        indexSetFilters={setFilters}
                        indexSetParams={setParams}
                        initialPage={page}
                        initialRowsPerPage={rowsPerPage}
                        initialFilters={filters}
                        initialParams={params}
                    />
                )
            default:
                return <>Nothing to show here</>
        }
    }

    return (
        <>
            {
                printSuc &&
                <div className="print-notif">
                    <Alert
                        option={{ type: 'success', message: "Printed Settlement Successfully" }}
                    />
                </div>
            }
            {
                printErr &&
                <div className="print-notif">
                    <Alert
                        option={{ type: 'error', message: "Settlement Failed to Print" }}
                    />
                </div>
            }
            {renderContent(content)}
        </>
    )
}

export default SettlementHistory