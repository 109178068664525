import React, { useState, useEffect } from 'react'
import { getAuthToken, globalDialog, money, scrollToTop } from '../../../Helper'
import { debounce } from 'lodash'

/** ICONS */
import PrintIcon from '@material-ui/icons/Print';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

/** Components */
import { globalTableStyle } from 'Configs/table'
import Alert from 'Comps/elements/Alert'
import MUIDataTable from 'mui-datatables'
import KopSurat from 'Elements/KopSurat'
import ContentWrapper from 'Comps/container/ContentWrapper'
import { makeGetParam } from 'Configs/request.js'

/** settlement history Redux */
import {
  deleteSettlementHistory,
  resetStatusSettlementHistory
} from 'Reduxes/pos/settlement-history/settlementHistoryActions'

import { useDispatch, useSelector } from 'react-redux'
import PrintProvider, { Print } from 'react-easy-print'
import CustomToolbar from '../../../comps/elements/CustomToolbar'
import { TableConfigOnDownload } from '../../../configs/on-download-table'
import { Chip, CircularProgress, Typography } from '@material-ui/core'
import SettlementHistoryFilter from './SettlementHistoryFilter'
import { CustomSortColumn } from '../../../utils/SortColumn'
import moment from 'moment';

import './index.scss'

const TableDataSSR = (props) => {
  const classes = globalTableStyle()

  const { error, success } = useSelector((state) => state.settlementHistoryReducer)

  const {
    indexSetPage,
    indexSetRowsPerPage,
    indexSetFilters,
    indexSetParams,
    initialPage,
    initialRowsPerPage,
    initialFilters,
    initialParams,

    printSettlement,
    downloadPdf,
  } = props

  const dispatch = useDispatch()

  const handleDelete = (id) => {
    globalDialog(async () => {
      await dispatch(deleteSettlementHistory(id))
      scrollToTop()
      fetchSettlementHistory()
    })
  }
  const [page, setPage] = useState(initialPage ?? 0)
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage ?? 10)
  const [count, setCount] = useState(0)
  const [data, setData] = useState()
  const [isLoading, setLoading] = useState(false)

  const initialFiltersValue = Object.keys(initialFilters)?.length > 0 ? initialFilters : {
    startDate: '',
    endDate: ''
  }

  const [filters, setFilters] = useState(initialFiltersValue)
  const [params, setParams] = useState('')

  /** Fetch SettlementHistory */
  useEffect(() => {
    fetchSettlementHistory()
  }, [])

  const fetchSettlementHistory = () => {
    setLoading(true)

    if (Object.keys(initialFilters).length > 0 && initialParams) {
      loadSettlementHistory(page, rowsPerPage, initialParams).then((res) => {
        setData(res.data)
        setLoading(false)
        setCount(res.total)
      })
    } else {
      loadSettlementHistory(page).then((res) => {
        setData(res.data)
        setLoading(false)
        setCount(res.total)
      })
    }

  }

  /** Fetch Settlement history
   * @param page integer
   * @param rowsPerPage integer
   * @param params Parameter URL
   */
  const loadSettlementHistory = async (page, rowPerPage, params = null) => {
    if (!rowPerPage) rowPerPage = rowsPerPage
    
    return new Promise(async (resolve, reject) => {
      const auth = getAuthToken()
      const headers = {
        Authorization: auth,
        method: 'GET'
      }

      let query = `${process.env.PW_SETTLEMENT_URL}/index-history`

      if (params) {
        query += `?page=${page + 1}&limit=${rowPerPage}${params}`
        const idxQuestionMarkParam = query.indexOf('?')
        query = query
          .split('')
          .map((str, idx) => {
            if (str === '?' && idx > idxQuestionMarkParam) {
              return '&'
            }
            return str
          })
          .join('')
      } else {
        query += `?page=${page + 1}&limit=${rowPerPage}`
      }

      const settlementHistory = await fetch(query, {
        headers
      })
        .then((res) => res.json())
        .catch(({ message }) => resolve({ message }))

      if (settlementHistory) {
        const total = settlementHistory?.meta?.totalItems
        const data = settlementHistory?.data

        resolve({
          data,
          total
        })
      }

      reject()
    })
  }

  const changePage = (page, rowsPerPage, params = null) => {
    setLoading(true)

    loadSettlementHistory(page, rowsPerPage, params).then((res) => {
      setTimeout(() => {
        setLoading(false)
        setPage(page)
        setData(res.data)
        setCount(res.total)
        setRowsPerPage(rowsPerPage)
        indexSetRowsPerPage(rowsPerPage)
        indexSetPage(page)
      }, 300)
    })
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        viewColumns: false,
        filter: false
      }
    },
    {
      name: 'no',
      label: 'No.',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const tableState = tableMeta.tableState
          const { page, rowsPerPage } = tableState
          if (page >= 1) {
            const result = tableMeta.rowIndex + 1 + page * rowsPerPage
            return result
          }
          return tableMeta.rowIndex + 1
        }
      }
    },
    {
      name: 'settle_date',
      label: 'Settle Date & Time',
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (value) {
            return (
              <div>{moment.utc(value).format('YYYY-MM-DD HH:mm')}</div>
            )
          }
          return ''
        }
      }
    },
    {
      name: 'settle_by',
      label: 'Settle By',
      options: {
        filter: false
      }
    },
    {
      name: 'sub_total',
      label: 'Subtotal Transaction',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (
            <div>IDR {money(Number(value ?? 0))}</div>
          )
        }
      }
    },
    {
      name: 'discount',
      label: 'Total Discount',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (
            <div>IDR {money(Number(value ?? 0))}</div>
          )
        }
      }
    },
    {
      name: 'total',
      label: 'Total Transaction',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (
            <div>IDR {money(Number(value ?? 0))}</div>
          )
        }
      }
    },
    {
      name: 'settle_id',
      label: 'Actions',
      options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            right: 0,
            zIndex: 51
          }
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            right: '0',
            background: 'white',
            zIndex: 51
          }
        }),
        filter: false,
        print: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = tableMeta?.tableData?.find((item) => item?.settle_id === value)
          const attrShow = {
            className: 'row-action',
            onClick: () => printSettlement(data)
          }

          const attrPdf = {
            className: 'row-action',
            onClick: () => downloadPdf(data)
          }

          return (
            <>
              <PrintIcon {...attrShow} />
              <PictureAsPdfIcon {...attrPdf} />
            </>
          )
        }
      }
    }
  ]

  const handleSearch = debounce((keyword, props) => {
    setLoading(true)
    const params = makeGetParam({ q: keyword })
    loadSettlementHistory(page, rowsPerPage, params).then((res) => {
      setData(res.data)
      setCount(res.total)
      setLoading(false)
    })
  }, 1000)

  const handleFilterSubmit = ({
    startDate,
    endDate,
  }) => {
    setLoading(true)
    let params = ''

    setFilters({
      startDate,
      endDate,
    })

    indexSetFilters({ startDate, endDate })


    if (startDate && endDate) {
      params += makeGetParam({ startDate, endDate })
    }

    setParams(params)
    indexSetParams(params)

    setTimeout(() => {
      loadSettlementHistory(0, 10, params).then((res) => {
        setData(res.data)
        setCount(res.total)
        setLoading(false)
      })
    }, 300)
  }

  const serializeSelectValue = (data) => {
    return data.map((data) => data.value)
  }

  const onDeleteChip = (title) => {
    switch (title) {
      case 'Date':
        filters.startDate = ''
        filters.endDate = ''
        setFilters(filters)
        break
      default:
    }

    handleFilterSubmit(filters)
  }

  /**
   * @param get label value from options  { value: 'val', label: 'Label' }
   * @returns label
   */

  /**
   *
   * @returns Component Chip
   */
  const RenderChipFilter = () => {
    /**
     * title: string
     * lists: (value for render) string[] | string
     */
    let keyProperty = []


    if (filters.startDate && filters.endDate) {
      keyProperty.push({
        title: 'Date',
        lists: `${filters.startDate} - ${filters.endDate}`
      })
    }

    return keyProperty.map(({ title, lists }) => (
      <>
        <Chip
          variant="outlined"
          color="secondary"
          label={`${title}: ${lists}`}
          key={title}
          // onDelete={() => onDeleteChip(title)}
        />
      </>
    ))
  }

  const handleDeleteBatch = (ids) => {
    const triggerDelete = async (ids) => {
      await Promise.all(
        ids.map(async (id) => {
          await dispatch(deleteSettlementHistory(id))
        })
      )
    }

    triggerDelete(ids)
    scrollToTop()
    fetchSettlementHistory()
    setLoading(false)
  }

  const options = {
    // responsive: 'stacked' /* scroll */,
    responsive: false /* scroll */,
    rowsPerPage: rowsPerPage || 10,
    colIdPosition: 0,
    filename: 'Settlement_History_Data',
    rowsPerPageOptions: [10, 25, 50],
    selectableRows: false,
    filter: true,
    serverSide: true,
    sort: true,
    print: false,
    count: count || 10,
    confirmFilters: true,
    page: page,
    customFilter: true,
    customFilterDialogFooter: () => (
      <SettlementHistoryFilter
        handleFilterSubmit={handleFilterSubmit}
        isLoading={isLoading}
        stateFilters={filters}
      />
    ),
    customToolbar: () => <CustomToolbar showPrint={true} />,
    onSearchChange: (keyword) => {
      handleSearch(keyword)
    },
    onDownload: (buildHead, buildBody, columns, values) =>
      TableConfigOnDownload(
        'Settlement_History_Data',
        buildHead,
        buildBody,
        columns,
        values
      ),
    onTableChange: (action, tableState) => {
      if (action === 'changePage' || action === 'changeRowsPerPage') {
        changePage(tableState.page, tableState.rowsPerPage, params)
      }
    },
    onRowsDelete: (e) => {
      const ids = e.data.map(({ index }) => index)

      const settlementHistoryIds = data
        .filter((val, idx) => ids.includes(idx))
        ?.map((data) => data.id)

      setLoading(true)
      handleDeleteBatch(settlementHistoryIds)
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true)

      const sortered = CustomSortColumn(changedColumn, direction, data)

      if (sortered.length) {
        setData(sortered)

        setTimeout(() => {
          setLoading(false)
        }, 300)
      }
    }
  }

  return (
    <PrintProvider>
      <Print single name="print-product-table-1">
        <KopSurat />
        <div className="spacer-60"></div>
        <ContentWrapper
          title="Settlement History"
          subtitle="showing table of all data settlement history"
        >
          <>
            {success && (
              <Alert
                hide={() => dispatch(resetStatusSettlementHistory())}
                option={{ type: 'success', message: success }}
              />
            )}
            {error && (
              <Alert
                hide={() => dispatch(resetStatusSettlementHistory())}
                option={{ type: 'error', message: error }}
              />
            )}

            <Typography variant="h6">
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>

            {filters && <RenderChipFilter />}
            {
              <MUIDataTable
                data={data}
                columns={columns}
                options={options}
                className={classes.table}
              />
            }
          </>
        </ContentWrapper>
      </Print>
    </PrintProvider>
  )
}

export default TableDataSSR
