import React, { useEffect } from 'react'
import { Field, ErrorMessage } from 'formik';
// import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DatePicker
} from '@material-ui/pickers';
import { datePost } from '@/Helper'
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';




const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        ...theme.pw.form.fieldContainerDatePicker
    },
    label: {
        ...theme.pw.form.label
    },
    input: {
        ...theme.pw.form.input
    },
    container: {
        ...theme.pw.form.input.container
    },
    errMsg: {
        ...theme.pw.form.input.errMsg
    }
}))



function Datepicker(props) {

    const { name, label, type, col, handleChange, minDate, value, disabled, clearable = true } = props;
    const formik = useFormikContext();
    const { setFieldValue, initialValues } = formik || {};
    const [localValue, setLocalValue] = React.useState()
    const classes = useStyles(props);


    useEffect(() => {
        formik && setFieldValue(name, minDate)
    }, [minDate])

    useEffect(() => {
        if (formik) {
            if (initialValues[name] !== '') {
                setFieldValue(name, initialValues[name])
                setLocalValue(initialValues[name])
            } else {
                if (!value) {
                    setFieldValue(name, datePost(new Date()))
                }
            }
        }

        setLocalValue(
            disabled ? null :
                !value ?
                    datePost(new Date()) :
                    (value === 'blank' ? null : value)
        )
    }, [])

    useEffect(() => {
        /* 
            untuk menghindari error pasang initialvalues nya new Date() | null | 'blank'
            janngan diisi string kosong ''
        */
        if (formik) {
            setLocalValue(initialValues[name] === 'blank' ? null : initialValues[name])
        }
    }, [initialValues]);




    const Comp = () => (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    // disableToolbar
                    // minDate="2020-12-12"
                    // {...(minDate ? {value: minDate } : { value })}
                    variant="inline"
                    autoOk={true}
                    format="yyyy-MM-dd"
                    name={name}
                    {...(minDate && { minDate })}
                    margin="normal"
                    id="date-picker-inline"
                    value={localValue}
                    onChange={x => {
                        setLocalValue(x ? datePost(x) : null)
                        formik && setFieldValue(name, datePost(x))
                        if (handleChange) {
                            handleChange({
                                value: datePost(x),
                                formik,
                                props
                            })
                        }
                    }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    variant="dialog"
                    clearable={clearable}
                    disabled={disabled}
                />

            </MuiPickersUtilsProvider>
            {formik && <ErrorMessage name={name} component="div" className="errMsg" />}
        </div>
    )



    return (
        <div className={classes.fieldContainer}>
            <label className={classes.label} htmlFor={name}>{label}</label>
            {
                formik ? (
                    <Field name={name} type="text" id={name}>
                        {
                            ({ form, field }) => <Comp />
                        }
                    </Field>
                ) : <Comp />
            }
        </div>
    )
}

export default Datepicker
