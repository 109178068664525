import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { deletePromotionConfiguration, setEditPromotionConfiguration, resetStatusPromotionConfiguration } from 'Reduxes/pos/promotion_configuration/promotionConfigurationActions'
import { tableOpts, globalTableStyle } from 'Configs/table'
import { scrollToTop, globalMessage, globalDialog } from '@/Helper'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import TableNoData from 'Comps/elements/TableNoData'
import LoadingContent from 'Elements/LoadingContent';
import Table from 'Elements/Table'
import CustomToolbar from "../../../comps/elements/CustomToolbar";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import Permission from "../../../comps/permission/Permission";
import KopSurat from 'Elements/KopSurat'


function TableData(props) {
    const { showForm, showDetail } = props
    const { data, loading, success } = useSelector(state => state.promotionConfigurationReducer);
    const dispatch = useDispatch()
    const classes = globalTableStyle();

    const handleDelete = id => {
        globalDialog(async () => {
            if (id.includes(1)) {
                globalMessage('You cannot delete master promotion', 'warning')
                return;
            }
            await dispatch(deletePromotionConfiguration(id))
            scrollToTop()
        })
    }

    const table = tableOpts({
        handleDelete,
        data,
        filter: false,
        colIdPosition: 0,
        filename: "Promotion_Configuration_Data",
        table: {
            columns: [
                {
                    name: 'id',
                    options: {
                        print: false,
                        filter: false,
                        display: false,
                        viewColumns: false
                    }
                },
                {
                    name: 'no',
                    label: 'No.',
                    options: {
                        customBodyRender: (value, tableMeta) => {
                            return tableMeta.rowIndex + 1
                        }
                    }
                },
                { name: 'code', label: 'Code' },
                { name: 'name', label: 'Promotion Name' },
                {
                    name: 'promotion_branches',
                    label: 'Branch',
                    options: {
                        customBodyRender: (val) => {
                            if (!val) return '-'
                            const parsed = val?.map(val => val?.branch_name)
                            return parsed.join(', ')
                        }
                    }
                },
                {
                    name: 'user_target',
                    label: 'User',
                    options: {
                        customBodyRender: (val) => {
                            const parsed = val.map(a => a.charAt(0).toUpperCase() + a.substr(1));
                            return parsed.join(', ')
                        }
                    }
                },
                {
                    name: "date_start",
                    label: 'Start Date',
                    options: {
                        customBodyRender: (val, tableMeta) => {
                            const { rowData } = tableMeta
                            const defaultPromotion = rowData[0] === 1
                            if (defaultPromotion) {
                                return ''
                            } else {
                                return val
                            }
                        }
                    }
                },
                {
                    name: "date_end",
                    label: 'End Date',
                    options: {
                        customBodyRender: (val, tableMeta) => {
                            const { rowData } = tableMeta
                            const defaultPromotion = rowData[0] === 1
                            if (defaultPromotion) {
                                return ''
                            } else {
                                return val
                            }
                        }
                    }
                },
                {
                    name: "type",
                    label: 'Type',
                    options: {
                        customBodyRender: (value, x) => {
                            return x.rowData[0] === 1 ? null : value.charAt(0).toUpperCase() + value.slice(1)
                        }
                    }
                },
                {
                    name: "status", label: 'Status', options: {
                        customBodyRender: (value, tableMeta) => {
                            return value === true ? "Active" : "Inactive"
                        }
                    }
                },
                {
                    name: "id",
                    label: 'Actions',
                    options: {
                        setCellHeaderProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: 0,
                                zIndex: 51
                            }
                        }),
                        setCellProps: () => ({

                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: "0",
                                background: "white",
                                zIndex: 51
                            }
                        }),
                        print: false,
                        customBodyRender: (value, tableMeta) => {
                            const statusIsDraft = tableMeta.rowData[8] === 'draft';
                            const attrShow = {
                                className: 'row-action',
                                onClick: () => showDetail(value)
                            }
                            const attrEdit = {
                                className: 'row-action',
                                onClick: () => {
                                    dispatch(setEditPromotionConfiguration(value))
                                    showForm('Edit')
                                    scrollToTop()
                                }
                            }
                            const attrDelete = {
                                className: 'row-action',
                                onClick: () => handleDelete([value])
                            }

                            return (
                                <>
                                    <VisibilityIcon {...attrShow} />
                                    <Permission
                                        component={<EditIcon {...attrEdit} />}
                                        subMenu='promotion'
                                        type='update'
                                    />
                                    {
                                        value > 1 && <Permission
                                            component={<DeleteIcon {...attrDelete} />}
                                            subMenu='promotion'
                                            type='delete'
                                        />
                                    }

                                </>
                            );
                        }
                    }
                },
            ],
        },
    })

    return (
        <PrintProvider>
            <Print single name="print-pc-table-1" >
                <KopSurat />
                <div className="spacer-60"></div>
                <ContentWrapper
                    title="Promotion Information"
                    subtitle="showing table of all data promotion"
                    buttons={[
                        {
                            label: 'Add promotion',
                            style: 'OrangeMd',
                            icon: 'plus-circle',
                            onClick: () => showForm('New'),
                            subMenu: 'promotion',
                            type: 'create',
                        }
                    ]}
                >
                    <>
                        {
                            success &&
                            <Alert hide={() => dispatch(resetStatusPromotionConfiguration())} option={{ type: 'success', message: success }} />
                        }

                        {
                            loading ? <LoadingContent />
                                : (
                                    data.length ?
                                        <Table {...table} className={classes.table} />
                                        : <TableNoData />
                                )
                        }
                    </>
                </ContentWrapper>
            </Print>
        </PrintProvider>
    )
}

export default TableData
