import isElectron from "is-electron";
import { globalMessage } from '@/Helper'
import moment from "moment";
import { logo } from './logo'

function renderCurrency(value) {
  return `
    <div style="display: flex; justify-content: space-between;">
      <span>Rp. </span>
      <span>${Number(value ?? 0)?.toLocaleString('ID-id')},-</span>
    </div>
  `
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const dummyData = {
  total: {
    sub_total: '899000',
    total: '899000',
    discount: '0',
  },
  payments: [
    {
      name: 'cash',
      count: '1',
      total: '899000',
    },
  ],
  items: [
    {
      name: 'JAVELIN JACKET',
      value: 'XS',
      total: '6',
    },
  ],
  user: 'Admin 4',
  branch: {
      branch_name: 'Maulana Yusuf',
      address: 'Jl.Maulana Yusuf No.2',
  },
  settled_date: '2025-01-10T04:20:14.030Z',
};


export default function printSettlement(settlement, options) {
  if (isElectron()) {
    console.log("struct : " + JSON.stringify(settlement));
    let { remote } = window.require("electron");

    console.log(remote.app.getAppPath());
    const { PosPrinter } = remote.require("electron-pos-printer");

    let printerName = options?.printerName

    const listPrinter = remote.BrowserWindow.getFocusedWindow().webContents.getPrinters();
    const found = listPrinter.find(printer => printer.name === options.printerName);

    if (options?.isPdf) {
      const printer = listPrinter?.find((printer) => printer?.name?.toLowerCase()?.includes('pdf'))
      if (printer) {
        printerName = printer?.name
      } else {
        throw new Error('Failed to save as pdf!');  
      }
    }

    if (!found) {
      throw new Error('PW_POS_PRINTER Not Found');
    }

    const detail = settlement?.detail?.[0]

    const header = `
      <div style="font-family: sans-serif;">
        <div style="display: flex; gap: 12px; align-items: center;">
          <img style="height: 60px;" src=${logo} />
          <div>
            <h1 style="font-size: 1.4rem; line-height: 24px; margin: 0;">Phillipworks</h1>
            <div style="font-weight: bold; margin: 0;">
              <div>${detail?.branch_name ?? ''}</div>
              <div>${detail?.address ?? ''}</div>
            </div>
          </div>
        </div>
        <div style="margin-top: 18px; display: flex; align-items: end; justify-content: space-between;">
          <div>Settled By: ${detail?.user ?? ''}</div>
          <div>
            <div>${moment(settlement?.history?.reprint_date ?? detail?.settled_date).format('DD MMMM YYYY HH:mm')}</div>
          </div>
        </div>
        <div style="border-bottom: 1px solid black; margin: 10px 0px;"></div>
        <div style="margin-bottom: 10px; text-align: right;">Transaction Date: ${moment(settlement?.transaction_date).format('DD MMMM YYYY')}</div>
      </div>
    `

    const summaryData = [
      {
        label: 'Subtotal',
        value: renderCurrency(settlement?.total?.sub_total),
      },
      {
        label: 'Total Discount',
        value: renderCurrency(settlement?.total?.discount),
      },
      {
        label: 'Total',
        value: renderCurrency(settlement?.total?.total),
      },
    ]

    const summary = `
      <div style="font-family: sans-serif;">
        ${summaryData?.map((item) => {
          return `
            <div style="display: flex; margin-bottom: 5px;">
              <div style="width: 100px;">${item?.label}</div>
              <div style="width: 100px;">${item?.value}</div>
            </div>
          `
        }).join('')}
      </div>
    `

    function capitalizeFirstLetter(string) {
      const words = string?.split(' ')?.map((item) => item?.charAt(0).toUpperCase() + item.slice(1));
      return words?.join(' ')
    }

    const payments = Object.keys(settlement?.payments)?.map((key) => {
      const value = settlement?.payments[key];
      if (value) {
        return value
      }
    }).filter(Boolean)

    const salesByPayment = `
      <div style="margin-top: 18px; font-family: sans-serif;">
        <div style="font-weight: bold;">Sales by payment method</div>
        <table style="margin-top: 10px;">
          <tbody style="border: none;">
            ${payments?.length > 0 ? payments?.map((item) => {
              return `
                <tr style="font-family: sans-serif; text-align: left; border: none;">
                  <td style="font-weight: bold;">${capitalizeFirstLetter(item?.name?.split('_')?.join(' '))} ${item?.edc_id ?? ''}</td>
                  <td style="min-width: 45px;">${item?.count}</td>
                  <td>${renderCurrency(item?.total)}</td>
                </tr>
              `
            }).join('') : '-'}
          </tbody>
        </table>
      </div>
    `

    const items = Object.keys(settlement?.items)?.map((key) => {
      const value = settlement?.items[key];
      if (value) {
        return value
      }
    }).filter(Boolean)


    const salesByItem = `
      <div style="margin-top: 18px; font-family: sans-serif;">
        <div style="font-weight: bold;">Sales by qty item</div>
        <table style="margin-top: 10px;">
          <tbody style="border: none;">
            ${items?.length > 0 ? items?.map((item) => {
              return `
                <tr style="font-family: sans-serif; text-align: left; border: none;">
                  <td>${item?.name}</td>
                  <td style="padding: 0 10px;">${item?.value ?? ''}</td>
                  <td style="text-align: right;">${item?.total ?? ''}</td>
                </tr>
              `
            }).join('') : '-'}
          </tbody>
        </table>
        <div style="border-bottom: 1px solid black; margin: 10px 0px;"></div>
      </div>
    `

    const footer = `
      <div style="text-align: center; font-weight: bold; font-size: 0.8rem; font-family: sans-serif; padding-bottom: 24px;">
        *** ${settlement?.type ? `${capitalizeFirstLetter(settlement?.type)} ` : ''}Settlement Confirmation ***
      </div>
    `

    const data = [
      {
        type: 'text',
        value: header,
        width: 'auto'
      },
      {
        type: 'text',
        value: summary,
        width: 'auto'
      },
      {
        type: 'text',
        value: salesByPayment,
        width: 'auto'
      },
      {
        type: 'text',
        value: salesByItem,
        width: 'auto'
      },
      {
        type: 'text',
        value: footer,
        width: 'auto'
      },
    ];

    let widthPage = "300px";

    const options_print = {
      preview: false, // Preview in window or print
      width: widthPage, //  width of content body
      margin: "0 0 0 0", // margin of content body
      copies: 1, // Number of copies to print
      printerName: printerName, // printerName: string, check it at webContent.getPrinters()
      timeOutPerLine: 400,
      silent: true,
    };

    const d = [...data];

    if (printerName && widthPage) {
      PosPrinter.print(d, options_print)
      .then(() => { })
      .catch((error) => {
        console.error(error);
      });
    } else {
      globalMessage("Select the printer and the width", 'info');
    }
    console.log(data);
  }
}
