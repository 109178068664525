import {
    SETTLEMENT_HISTORY_LOADING,
    SETTLEMENT_HISTORY_SET,
    SETTLEMENT_HISTORY_ERROR,
    SETTLEMENT_HISTORY_ADD,
    SETTLEMENT_HISTORY_SHOW,
    SETTLEMENT_HISTORY_DELETE,
    SETTLEMENT_HISTORY_UPDATE,
    SETTLEMENT_HISTORY_RESET,
    SETTLEMENT_HISTORY_EDIT,
    SETTLEMENT_HISTORY_CONFIRM,
    SETTLEMENT_HISTORY_STOP_LOADING
} from './settlementHistoryActions'


const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
}

const settlementHistoryReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case SETTLEMENT_HISTORY_STOP_LOADING:
            return {
                ...state,
                loading: false,
            }
        case SETTLEMENT_HISTORY_LOADING:
            return {
                ...state,
                loading: true,
            }
        case SETTLEMENT_HISTORY_UPDATE:
            // ketika pindah ke done idEdit tidak dipasang seperti seharusnya 
            // jadi diakali ngambil dari pyload ny 
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === (state.idEdit || payload.id) ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case SETTLEMENT_HISTORY_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case SETTLEMENT_HISTORY_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New Receipts has been added successfully.'
            }
        case SETTLEMENT_HISTORY_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case SETTLEMENT_HISTORY_SHOW:
            return {
                ...state,
                idShow: payload,
                success: null,
                error: null
            }
        case SETTLEMENT_HISTORY_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case SETTLEMENT_HISTORY_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload,
            }
        case SETTLEMENT_HISTORY_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        case SETTLEMENT_HISTORY_STOP_LOADING: {
            return {
                ...state,
                loading: false
            }
        }
        case SETTLEMENT_HISTORY_CONFIRM:
            console.log('111111111111', payload, type);

            let newData = state.data.map(d => {
                if (d.id === payload.id) {
                    d.status = payload.type;
                }
                return d
            })

            return {
                ...state,
                data: newData

            }
        default:
            return state;
    }
}

export default settlementHistoryReducer