import { getAuthToken } from '@/Helper'

const baseUrl = process.env.PW_SETTLEMENT_URL

export async function getDetailHistory(id) {
  try {
    const url = `${baseUrl}/reprint/${id}`
    const req = await fetch(url, {
      method: 'post',
      headers: {
        "Authorization": getAuthToken(),
      }
    })
    const response = await req.json()
    return response?.data
  } catch (e) {
    return null
  }
}