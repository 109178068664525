import { getAuthToken } from '@/Helper'

const API_URL = process.env.PW_SETTLEMENT_URL;
const settleUrl = API_URL + '/settle'

export async function requestSettlement() {
  const req = await fetch(settleUrl, {
    method: 'post',
    headers: {
      "Authorization": getAuthToken(),
    }
  })
  const response = await req.json()
  return response
}